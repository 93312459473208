'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { SVGProps } from 'react'
const Length = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M8.5 17.3L3 12l5.5-5.3 1.2 1.1-3.5 3.4h11.7l-3.5-3.3 1.1-1.1L21 12l-5.5 5.3-1.2-1.1 3.6-3.4H6.1l3.5 3.4z"
    />
  </svg>
)
export default Length
